.trainers-div{
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: black;
    
}



.trainer-item .card{
    margin: 1rem;
    border: 5px solid #FF5656;
    box-shadow: inset 0 0 0 0 #FF5656;
    color: black;
    transition: color .2s ease-in-out, box-shadow .2s ease-in-out;
    height: 30rem;
}

.card-img-top{
    border-bottom: px solid #FF5656;
}

.trainer-item .card:hover{
    color: #fff;
    box-shadow: inset 15rem 0 0 0 #FF5656;;
}

@media (max-width: 768px) {
    .trainer-item .card{
        width: 90% !important;
    }

    .trainer-item .card:hover{
        color: #fff;
        box-shadow: inset 35rem 0 0 0 #FF5656;;
    }
}