.login-div{
    margin: 4rem;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.login-form .nav-link{
 padding: 0;
 margin: 1rem;
 margin-left: 0;
}

#register-form .btn{
    color: black;
    width: 7rem;
    height: 3rem;
    text-align: center;
    background-color: white;
    border-radius: 5px;
    border: 2px solid white;
    font-size: 1rem;
    transition: 0.3s;
    border: 4px solid #FF5656;
}

#register-form .btn:hover{
    background-color: #FF5656;

color: white;
}

@media (max-width: 768px) {
    .login-div{
        width: 95%;
    }
}