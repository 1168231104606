.editprofile-heading{
    margin: 4rem;
    color: #FF5656;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
}

#formFileLg{
    font-size: 0.8rem;
    width: 50%;
}

.userprofile-div span{
    margin: 1rem;
}

.update-btn{
    border: 5px solid #FF5656;
    height: 3rem !important;
    width: 10rem;
    border-radius: 5px;
    transition: 0.3s;
}

.update-btn:hover{
    background-color: #FF5656;
    color: white !important;

}


