.page-title{
    margin: 2rem;
    font-size: 3rem;
    color: #FF5656;
    font-weight: bold;
    text-align: center;
}


.contactus-div{
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    margin-left: auto !important;
    margin-right: auto !important;
    margin: 3rem 0 3rem 0;
    justify-content: center;
}

.contactus-form-div{
    width: 50%;
}

.contactus-map{
    text-align: center;
    width: 50%;
    padding: 1rem;
}

.contactus-map iframe{
    width: 100%;
}

@media (max-width: 768px) {
    .contactus-form-div{
        width: 100%;
    }
    .contactus-map{
        text-align: center;
        width: 100%;
        margin-top: 2rem;
    }
    
}