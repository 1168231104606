.restepassword-div{
    width: 60%;
    margin: 4rem auto 4rem auto;
    color: white;
    border: 5px solid #FF5656;
    border-radius: 10px;
}

.resetpassword-heading{
    margin: 4rem;
    color: #FF5656;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
}

.resetpassword-form-div{
    text-align: center;
    width: 80%;
    margin: 4rem auto 4rem auto;
}

@media (max-width: 768px) {
    .restepassword-div{
        width: 95%;
    }
}