#root{
    background-color: black;
    color: white;
}

h2{
    font-weight: bold;
}

.carousel-div{

    margin-bottom: 5rem;
}

/* descriptions and video */
.home-description-div{
    display: flex;
    flex-wrap: wrap;
    margin: 2rem;
    margin: 4rem 2rem;

    margin-bottom: 5rem;
    justify-content: center;
}

.home-description-div div{
    width: 32rem;
}

.home-description_description{
    margin: auto 1rem auto 1rem;
}


/* testimonial */
.testimonial-div{
    text-align: center;
    margin: 0 5rem 5rem 5rem;
}

.testimonial-item{
    text-align: center;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    height: 25rem;
}


/* expert trainers */
.carousel-indicators{
    display: none;
}
.carousel-control-prev{
display: none;
}

.carousel-control-next{
display: none;
}

.carousel-div{
    display: flex;
    justify-content: center;
}


.expert-trainers-div{
    text-align: center;
    margin: 5rem;
    color: black;
}

.home-description_video{
    height: 20rem;
}

.expert-trainers-item{
    text-align: center;
}

.expert-trainers-item div{
    display: inline-table;
    margin: 0.5rem;
}

.expert-trainers-item .card{
    height: 38rem;
    margin: 1rem;
    border: 5px solid #FF5656;
    box-shadow: inset 0 0 0 0 #FF5656;
    color: black;
    transition: color .2s ease-in-out, box-shadow .2s ease-in-out;
}

.expert-trainers-item .card:hover{
    color: #fff;
    box-shadow: inset 20rem 0 0 0 #FF5656;;
}

.expert-trainers-div .slide{
    padding-bottom: 4rem;
}

@media (max-width: 768px) {
    .home-description_description{
        text-align: center;
    }

    .testimonial-div{
        margin-left: 0;
        margin-right: 0;
    }

    .testimonial-item{
        width: 70%;  
    }

    .expert-trainers-div .card{
        width: 90% !important;
    }

    .expert-trainers-div{
        margin: 0;
    }

    .expert-trainers-item .card:hover{
        box-shadow: inset 40rem 0 0 0 #FF5656;;
    }
}